/* You can add global styles to this file, and also import other style files */
body{overflow:hidden;}

@font-face {
  font-family: Renogare;
  src: url("https://control.yuu.im/static/yuuk17/fonts/Renogare-Regular.otf") format("opentype");
}

#content {
  margin-top: -350px;
  background: white;
  /* height: 825px; */
  z-index: 1000;
  border-radius: 15px;
  overflow-y: auto;
  padding-bottom: 15px;
}
.weather {
  height: 100px;
  color: gray;
}

#weather-0{
}
#weather-1{
}
#weather-2{
}
#weather-3{
}

.clouds-day-FEW {
  background: rgba(255,255,255,0.1);
}
.clouds-day-SCT {
  background: rgba(255,255,255,0.2);
}
.clouds-day-BKN {
  background: rgba(255,255,255,0.3);
}
.clouds-day-OVC {
  background: rgba(255,255,255,0.4);
}
.clouds-day-VV {
  background: rgba(255,255,255,0.5);
}

.clouds-sunrise-FEW {
  background: rgba(255,255,255,0.1);
}
.clouds-sunrise-SCT {
  background: rgba(255,255,255,0.2);
}
.clouds-sunrise-BKN {
  background: rgba(255,255,255,0.3);
}
.clouds-sunrise-OVC {
  background: rgba(255,255,255,0.4);
}
.clouds-sunrise-VV {
  background: rgba(255,255,255,0.5);
}

.clouds-night-FEW {
  background: rgba(0,0,0,0.1);
}
.clouds-night-SCT {
  background: rgba(0,0,0,0.2);
}
.clouds-night-BKN {
  background: rgba(0,0,0,0.3);
}
.clouds-night-OVC {
  background: rgba(0,0,0,0.4);
}
.clouds-night-VV {
  background: rgba(0,0,0,0.5);
}

.clouds-sunset-FEW {
  background: rgba(143,143,143,0.1);
  color: white;
}
.clouds-sunset-SCT {
  background: rgba(143,143,143,0.2);
  color: white;
}
.clouds-sunset-BKN {
  background: rgba(143,143,143,0.3);
  color: white;
}
.clouds-sunset-OVC {
  background: rgba(143,143,143,0.4);
  color: white;
}
.clouds-sunset-VV {
  background: rgba(143,143,143,0.5);
  color: white;
}

.bg-sunrise-clear{
  background: #aee3ff;
}
.bg-sunrise-cloudy{
  background: #c1d7e2;
}

.bg-sunset-clear{
  background: #e1762c;
}
.bg-sunset-cloudy{
  background: #c98c5c;
}

.bg-day-clear{
  background: #00BFFF;
}
.bg-day-cloudy{
  background: #a0bbc3;
}

.bg-night-clear{
  background: #1c0235;
}
.bg-night-cloudy{
  background: #322e37;
}

.card-border {
  border: 1px black solid;
}
.image-inoutbox {
  border-radius: 50%;
  padding: 0;
  max-width: 150px;
  max-height: 150px;
}

#celeste {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  color: white;
  line-height: 20;
  text-align: center;
  font-family: Renogare;
  font-size: 36px;
  background: linear-gradient(-45deg, #0d1840, #341c37, #332840, #0c243f);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}
.y-red {
  color: red;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

#celeste canvas{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
#celeste span {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
}

.drop {
  background:-webkit-gradient(linear,0% 0%,0% 100%, from(rgba(13,52,58,1) ), to(rgba(255,255,255,0.6))  );
  background: -moz-linear-gradient(top, rgba(13,52,58,1) 0%, rgba(255,255,255,.6) 100%);
  opacity: 0.3;
  width:1px;
  height:89px;
  position: absolute;
  bottom:200px;
  -webkit-animation: fall .63s linear infinite;
  -moz-animation: fall .63s linear infinite;
  z-index: -1;
}
.rain {
  z-index: -1;
}

/* animate the drops*/
@-webkit-keyframes fall {
  to {margin-top:900px;}
}
@-moz-keyframes fall {
  to {margin-top:900px;}
}
.spinning {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.pulse {
  box-shadow: 10px 10px 10px rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255,255,255, 0);
    box-shadow: 0 0 0 10px rgba(255,255,255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
